import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import KnockoutPage, { KnockoutPageData } from 'templates/KnockoutPage';

export const query = graphql`
  query {
    csKnockoutPage(page_id: { eq: "permanent-driver-and-hirer-rejected-agreement-terms" }) {
      ...KnockoutPage
    }
  }
`;

const DriverRejectedAgreementTerms: React.FC = () => {
  const data = useStaticQuery<KnockoutPageData>(query);
  return <KnockoutPage data={data} />;
};

export default DriverRejectedAgreementTerms;
